<template>
  <div id="account-management">
    <NForm inline label-placement="left">
      <NFormItem label="搜索：">
        <NInput
          style="width: 300px;"
          maxlength="50"
          show-count
          clearable
          :placeholder="`请输入${courseTypeValNameMap[courseTypeVal]}名称或${courseTypeValNameMap[courseTypeVal]}ID`"
          v-model:value="formValue.title"
          @update:value="ChangeTitle"
        />
      </NFormItem>
      <NFormItem>
        <NSpace>
          <NButton type="primary" @click="handleSearch">搜索</NButton>
          <NButton @click="handleClear">清除</NButton>
        </NSpace>
      </NFormItem>
    </NForm>

    <div class="create-btn-box">
      <n-button
        type="primary"
        class="create-btn"
        @click="handleClickCreate"
        v-if="ownUrlPermission('course/create')"
        >新增{{ courseTypeValNameMap[courseTypeVal] }}</n-button
      >
    </div>
    <NTabs
      type="line"
      @before-leave="handleTabBeforeLeave"
      @update:value="handleTabChange"
    >
      <n-tab-pane
        v-for="item in resourceTypes"
        :key="item.type"
        :name="item.type"
        :tab="item.name"
      >
      </n-tab-pane>
    </NTabs>
    <NAlert
      v-show="BookLength.length > 0"
      style="margin-bottom: 10px"
      type="info"
    >
      已选择
      <span :style="{ color: '#33b6ff' }">{{ BookLength.length }}</span> 项数据
    </NAlert>
    <div style="margin-bottom: 10px">
      <NButton
        :disabled="BookLength.length === 0"
        type="success"
        @click="DistributionInstitution(1)"
        style="margin-right: 10px"
        v-if="ownUrlPermission('course/distribute')"
      >批量分发</NButton>
      <NButton
        :disabled="BookLength.length === 0"
        type="error"
        v-if="ownUrlPermission('course/delete')"
        @click="batDel"
      >批量删除</NButton>
    </div>
    <n-data-table
      remote
      ref="table"
      :single-line="false"
      :bottom-bordered="true"
      :columns="columns"
      :data="Datas"
      :row-key="(row) => row.id"
      :loading="false"
      :pagination="pagination"
      @update:page-size="handlePageSizeChange"
      @update:page="handlePageChange"
      v-model:checked-row-keys="BookLength"
    />

    <CreateModal
      ref="createModalRef"
      :courseTypeVal="courseTypeVal"
      @list-update="handleSearch"
    />
    <!-- 分发组件 -->
    <Distribute
      ref="distributeRef"
      :distribute-title="`分发${courseTypeValNameMap[courseTypeVal]}`"
      :courseTypeName="courseTypeValNameMap[courseTypeVal]"
      @isrefresh="isrefresh"
      :TrainingBook="TrainingBook"
      :label-type="resourceEnum.TEACHING_RESOURCE_TYPE"
      @distribute-success="handleDistributeSuccess"
    />
    <DistributeRecords ref="distributeRecordsRef" />
    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
import { reactive, ref, h, computed } from "vue";
import { NSpace, NButton, NTime, NPopconfirm, useMessage } from "naive-ui";

import accountType from "@/enumerators/account-type";
import { resStatusEnum } from "@/enumerators/http.js";
import {
  GetCourseIndex,
  PostCourseState,
  PostCourseDelete,
  PostCourseCreate,
  copyCourse
} from "@/api/course.js";
import { toRaw } from "@vue/reactivity";
import { useRouter } from "vue-router";
import PageLoading from "@/components/PageLoading/index.vue";
import { resourceEnum } from "@/enumerators/resource-types-map.js";
import Distribute from "@/components/CourseDistribution/Distribute.vue";
import ownUrlPermission from "@/utils/own-url-permission.js";

import CreateModal from './CreateModal.vue';
import DistributeRecords from './DistributeRecords.vue';

import { courseTypeValMap, courseTypeValNameMap } from '@/enums/course-type.js';
import { recommendAdd, recommendDel } from '@/api/recommend.js';

const resourceTypes = ref([
  { type: 2, name: "全部" },
  { type: 1, name: "使用中" },
  { type: 0, name: "已停用" },
]);

const props = defineProps({
  isrefresh: Boolean,
  courseTypeVal: {
    required: true,
    type: Number
  }
}) 

// 切换监控
function handleTabBeforeLeave() {
  return !loading.value;
}

// 点击切换
function handleTabChange(name) {
  let index = Number(name);
  if (index == 2) {
    formValue.stop = ""; // 类型赋值
  } else {
    formValue.stop = index; // 类型赋值
  }
  handlePageChange(1);
}

const TrainingBook = ref([]);
const BookLength = ref([]);

// 是否刷新页面
const isrefresh = (is) => {
  console.log("是否刷新页面", is);
};

const message = useMessage();
const router = useRouter();
// 总数据
const Datas = ref([]);
const columns = [
  { type: "selection" },
  {
    title: `${courseTypeValNameMap[props.courseTypeVal]}ID`,
    key: "id",
  },
  {
    title: `${courseTypeValNameMap[props.courseTypeVal]}名称`,
    key: "base_name",
  },
  {
    title: "创建人",
    key: "admin_username",
  },
  {
    title: "更新日期",
    render: (row) =>
      h(NTime, {
        time: (Number(row.update_time) || 0) * 1000,
      }),
  },
  /*
  {
    title: "用于授课数",
    key: "lecture_num",
  },
  */
  {
    title: '使用情况',
    width: 90,
    render: ({ change }) => {
      change = Number(change);
      let propObj = null;
      let txt = '已使用';
      if (change === 0) {
        propObj = { style: 'color: #ccc;' };
        txt = '未使用'
      }
      return h('span', propObj, txt);
    }
  },
  {
    title: "状态",
    width: 85,
    render: (row) =>
      h("span", { class: Number(row.stop) === 0 ? 'error-color' : 'success-color' }, getResourceName(row.stop)),
  },
  {
    title: "操作",
    width: 200,
    key: "stop",
    render: (row) =>
      h(NSpace, null, {
        default: () => Operation(row),
      }),
  },
];

// 数据请求 / 分页所需--------------
const { SUCCESS } = resStatusEnum;
const loading = ref(false);

const idCourseItemMap = {};
// 数据初始化
function updateList(data) {
  loading.value = true;
  // 请求列表数据
  GetCourseIndex(data)
    .then((res) => {
      if (res.code === SUCCESS) {
        loading.value = false;
        Datas.value = res.data.list;
        res.data.list.forEach(item => {
          idCourseItemMap[item.id] = item;
        });
        updateItemCount(res.data.total);
        // console.log("请求列表数据3:",Datas)
      }
    })
    .catch((reject) => {});
}

// 状态
function getResourceName(stop) {
  return accountType[stop] || "";
}

function copyItem({ id, base_name }) {
  loading.value = true;
  copyCourse({
    'Course[id]': id,
  }).then(({ code }) => {
    if (code === SUCCESS) {
      message.success(`“${base_name}”复制成功`);
      handleSearch();
    }
  }).finally(() => {
    loading.value = false;
  });
}
function editBriefInfo({ id }) {
  createModalRef.value.openEditModal(id);
}

const distributeRecordsRef = ref(null);

//操作
function Operation(row) {
  let tempArr = [];
  let arr1 = h(
    NButton,
    { text: true, type: "info", onClick: () => ViewDetails(row) },
    { default: () => "查看" }
  );
  let arr2 = h(
    NButton,
    {
      text: true,
      type: "info",
      onClick: () => DistributionInstitution(0, row),
    },
    { default: () => "分发院校" }
  );
  let arr3 = h(
    NButton,
    { text: true, type: "success", onClick: () => DisableEnable(row) },
    { default: () => "启用" }
  );
  let arr4 = h(
    NButton,
    { text: true, type: "info", onClick: () => toAddLabel(row) },
    { default: () => `${courseTypeValNameMap[props.courseTypeVal]}建设` }
  );
  let arr5 = h(
    NButton,
    { text: true, type: "error", onClick: () => toDelLabel(row) },
    { default: () => "删除" }
  );
  let arr6 = h(
    NButton,
    { text: true, type: "error", onClick: () => DisableEnable(row) },
    { default: () => "停用" }
  );
  const copyItemBtn = h(
    NPopconfirm,
    { onPositiveClick: () => copyItem(row) },
    {
      default: () => h('div', null, [h('span', null, '你确定要复制此内容吗？'), h('br'), h('span', null, '内容涉及MP3、MP4文件需重新上传。')]),
      trigger: () => h(NButton, { text: true, type: 'primary' }, { default: () => '复制' })
    }
  );
  const editBriefInfoBtn = h(
    NButton,
    { text: true, type: "info", onClick: () => editBriefInfo(row) },
    { default: () => '编辑信息' }
  );

  if (ownUrlPermission('recommend/create') && Number(row.is_recommend) === 0) {
    tempArr.push(h(
      NButton,
      {
        text: true,
        type: 'success',
        onClick: () => {
          loading.value = true;
          recommendAdd({
            Recommend: [
              {
                type: props.courseTypeVal + 1,
                source_id: row.id
              }
            ]
          }).then(({ code }) => {
            if (code === SUCCESS) {
              message.success(`已推荐：“${row.base_name}”`);
              row.is_recommend = 1;
            }
          }).finally(() => {
            loading.value = false;
          });
        }
      },
      { default: () => '推荐' }
    ));
  }
  if (ownUrlPermission('recommend/delete') && Number(row.is_recommend) === 1) {
    tempArr.push(h(
      NButton,
      {
        text: true,
        type: 'error',
        onClick: () => {
          loading.value = true;
          recommendDel({
            'Recommend[type_status]': 2,
            'Recommend[type]': props.courseTypeVal + 1,
            'Recommend[source_id]': row.id
          }).then(({ code }) => {
            if (code === SUCCESS) {
              message.success(`已取消推荐：“${row.base_name}”`);
              row.is_recommend = 0;
            }
          }).finally(() => {
            loading.value = false;
          });
        }
      },
      { default: () => '取消推荐' }
    ));
  }

  // 查看
  tempArr.push(arr1);
  // 分发院校
  if (Number(row.stop) === 1 && ownUrlPermission("course/distribute")) {
    tempArr.push(arr2);
  }
  if (ownUrlPermission('course/distribute-log')) {
    tempArr.push(h(
      NButton,
      { type: 'primary', text: true, onClick: () => distributeRecordsRef.value.initTableData(row) },
      { default: () => '分发情况' }
    ));
  }
  if (Number(row.stop) === 0 || Number(row.change) === 0) {
    // 课程建设
    if (ownUrlPermission("course/update")) {
      tempArr.push(arr4);
    }
  }
  if (ownUrlPermission("course/update")) {
    tempArr.push(editBriefInfoBtn);
  }
  if (ownUrlPermission("course/copy")) {
    tempArr.push(copyItemBtn);
  }
  if (Number(row.stop) === 0) {
    // 状态改变
    if (ownUrlPermission("course/state")) {
      tempArr.push(arr3);
    }
  } else {
    // 状态改变
    if (ownUrlPermission("course/state")) {
      tempArr.push(arr6);
    }
  }
  if (Number(row.stop) === 0) {
    // 删除
    if (ownUrlPermission("course/delete")) {
      tempArr.push(arr5);
    }
  }
  return tempArr;
}

// 课程建设
function toAddLabel(row) {
  let id = row.id;
  let path = '';
  switch (props.courseTypeVal) {
    case courseTypeValMap.COURSE:
      path = '/course/index/build';
      break;
    case courseTypeValMap.TEACHING_SYSTEM:
      path = '/course/index/teaching-system-build';
      break;
  }
  router.push({
    path,
    query: { id },
  });
}

const createModalRef = ref(null);
function handleClickCreate() {
  createModalRef.value.openCreateModal();
}

// 删除
function toDelLabel(row) {
  const dialog = window.$dialog;
  let data = {
    "Course[ids]": [row.id],
  };
  dialog.warning({
    title: "删除确认",
    content: `确定删除${courseTypeValNameMap[props.courseTypeVal]}：“${row.base_name}”？`,
    positiveText: "确定",
    negativeText: "取消",
    onPositiveClick: () => {
      loading.value = true;
      PostCourseDelete(data)
        .then((res) => {
          if (res.code === SUCCESS) {
            message.success(res.msg);
            // 成功后 调用接口刷新页面
            updateList(toRaw(formValue));
            // 删除后重置checked数据
            BookLength.value.splice(0);
          }
        })
        .catch((reject) => {})
        .finally(() => {
          loading.value = false;
        });
    },
    onNegativeClick: () => {},
  });
}
function batDel() {
  const dialog = window.$dialog;
  let data = {
    "Course[ids]": BookLength.value,
  };
  dialog.warning({
    title: "删除确认",
    content: `确定删除 ${BookLength.value.length} 个${courseTypeValNameMap[props.courseTypeVal]}？`,
    positiveText: "确定",
    negativeText: "取消",
    onPositiveClick: () => {
      loading.value = true;
      PostCourseDelete(data)
        .then((res) => {
          if (res.code === SUCCESS) {
            message.success(res.msg);
            // 成功后 调用接口刷新页面
            updateList(toRaw(formValue));
            // 删除后重置checked数据
            BookLength.value.splice(0);
          }
        })
        .catch((reject) => {})
        .finally(() => {
          loading.value = false;
        });
    },
    onNegativeClick: () => {},
  });
}

// 停用启用
function DisableEnable(row) {
  loading.value = true;
  var formDatas = new FormData();
  let data = {};
  data["Course[id]"] = row.id;
  data["Course[stop]"] = row.stop == "0" ? 1 : 0;
  PostCourseState(data)
    .then((res) => {
      if (res.code === SUCCESS) {
        loading.value = false;
        message.success(res.msg);
        // 成功后 调用接口刷新页面
        updateList(toRaw(formValue));
      }
    })
    .catch((reject) => {});
}

// 翻页 搜索参数集
var ParameterSet = {};

// 搜索
function handleSearch() {
  handlePageChange(1);
}

// 查看详情
function ViewDetails({ id }) {
  switch (props.courseTypeVal) {
    case courseTypeValMap.COURSE:
      window.open(`/full-page/course-preview?cid=${id}`);
      break;
    case courseTypeValMap.TEACHING_SYSTEM:
      window.open(`/full-page/teaching-system-detail?cid=${id}`);
      break;
  }
}

// 分发院校
function DistributionInstitution(is, row) {
  TrainingBook.value.splice(0);
  // 分发
  if (is == 0) {
    // 如果没有选择批量分发 则赋值 TrainingBook
    TrainingBook.value.push(row);
    distributeRef.value.openModal();
  }
  // 批量分发
  else {
    TrainingBook.value = BookLength.value.map(id => idCourseItemMap[id]);
    distributeRef.value.openModal();
  }
}

// 监控标题输入
function ChangeTitle(title) {
  pagination.page = 1;
  formValue.page = 1;
}

const pagination = reactive({
  page: 1,
  itemCount: 0,
  pageSize: 10,
  pageSizes: [10, 20, 30],
  showSizePicker: true,
});
const updateItemCount = (count) => {
  pagination.itemCount = Number(count) || 0;
};
const handlePageSizeChange = (pageSize) => {
  pagination.pageSize = pageSize;
  formValue.pagesize = pageSize;
  handlePageChange(1);
};
const handlePageChange = (page) => {
  pagination.page = page;
  formValue.page = page;
  ParameterSet = toRaw(formValue);
  updateList(ParameterSet);
};

// 分页结束-------------------

const getDefaultFormValue = () => ({
  title: "",
  stop: "",
  page: 1,
  pagesize: 10,
  data_type: props.courseTypeVal
});
const formValue = reactive(getDefaultFormValue());
const getSearchParams = () => {
  const params = {};
  Object.keys(formValue).forEach((key) => {
    const value = formValue[key];
    value && (params[key] = value);
  });
  return params;
};

const emit = defineEmits(["list-update"]);

const handleClear = () => {
  formValue.title = '';
  handleSearch();
};

// 分发
const distributeRef = ref(null);

const toDistribute = (row) => {
  // localStorage.setItem("schoolId",row.id);
  // localStorage.setItem("schoolName",row.school_name);
  distributeRef.value.openModal();
};
function handleDistributeSuccess() {
  BookLength.value.splice(0);
  handlePageChange(1);
}

updateList(toRaw(formValue));

defineExpose({
  getSearchParams,
});
</script>

<style scoped>
#account-management {
  height: 100%;
  background: #fff;
  padding: 20px;
  box-sizing: border-box;
}

.create-btn-box {
  position: relative;
  z-index: 1;
}
.create-btn {
  position: absolute;
  right: 0;
  top: 0;
}

.n-dialog__title {
  font-weight: 700 !important;
}
.storage-btns {
  float: right;
}
.storage-btn-kc {
  width: 180px;
}
</style>