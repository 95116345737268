<template>
  <div>
    <NForm label-placement="left" :label-width="100">
      <NFormItem>
        <template #default>
           <n-grid x-gap="12" :cols="3">
            <n-gi v-for="item in TrainingBook" :key="item.id">
              <div style="margin-bottom:20px">
                <div><span class="tabel-text1">{{ courseTypeName }}ID：</span><span class="tabel-text2"> {{item.id}}</span></div>
                <div><span class="tabel-text1">{{ courseTypeName }}名称：</span><span class="tabel-text2"> {{item.base_name}}</span></div>
                <div><span class="tabel-text1">创建人：</span><span class="tabel-text2"> {{item.admin_username}}</span></div>
              </div>
            </n-gi>
          </n-grid>
        </template>
      </NFormItem>
      <NFormItem>
        <template #label>
          <span style="color: #bbb;">已选分发学校：</span>
        </template>
        <template #default>
          <div class="school-texts">
            <span v-for="(item, index) in selectedLabelsStr" :key="item.id"  v-show="(index+1)!=selectedLabelsStr.length">{{ item.title }} 、</span>
            <span v-for="(item, index) in selectedLabelsStr" :key="item.id" v-show="(index+1)==selectedLabelsStr.length">{{ item.title }}</span>
          </div>
        </template>
      </NFormItem>
      <NFormItem>
        <template #label>
          <span style="color: #bbb;">总计：</span>
        </template>
        <template #default>
          <span>{{ Object.keys(book).length }}个{{ courseTypeName }}，{{ Object.keys(idLabel).length }}个学校</span>
        </template>
      </NFormItem>
    </NForm>

    <NSpace justify="end" style="margin: 12px -28px 0; padding: 15px 28px 0; border-top: 1px solid #ddd;">
      <NButton @click="emit('modal-close')">取消</NButton>
      <NButton @click="preStep">上一步</NButton>
      <NButton type="primary" @click="handleSubmit">确定</NButton>
    </NSpace>

    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
  import { ref, computed } from 'vue';

  import PageLoading from '@/components/PageLoading/index.vue';

  import { resStatusEnum } from '@/enumerators/http.js';

  import { PostCourseDistribute } from "@/api/course.js";

  import { toRaw } from "@vue/reactivity";

  const { SUCCESS } = resStatusEnum;
  
  const loading = ref(false);
  const props = defineProps({
    idLabel: {
      type: Object,
      default: () => {
        return {};
      }
    },
    idJlabel: {
      type: Object,
      default: () => {
        return {};
      }
    },
    TrainingBook:Array,
    courseTypeName: {
      type: String,
      default: ''
    }
  });
  

  const emit = defineEmits(['step-pre']);
  // , 'modal-close'
  const TrainingBook = props.TrainingBook
  
  var book = []
  TrainingBook.forEach(item=>{
    // console.log("book",item)
    book.push(item.id)
  })

  const selectedLabelsStr = computed(() => {
    const idLabel = props.idLabel;
    return idLabel
  });


  const selectedSchoolsStr = computed(() => {
    const idSchool = props.idSchool;
    let name = localStorage.getItem("schoolName")
    return name
  });

  

  const preStep = () => {
    emit('step-pre');
  };
  const handleSubmit = () => {
  loading.value = true;
  
  var datalabel = []   // idLabel

  for(var item1 in toRaw(selectedLabelsStr.value)){
    datalabel.push(selectedLabelsStr.value[item1].id)
  }

    PostCourseDistribute({
      "Data[schools]": datalabel,
      'Data[ids]': book
    }).then(res => {
      loading.value = false;
      if (res.code === SUCCESS) {
        window.$message.success('分发成功');
        emit('distribute-success');
        emit('modal-close');
      }
    }).catch(err => {
      loading.value = false;
    });

  };
</script>
<style lang="less" scoped>
.tabel-text1{
  display: inline-block;
  width: 105px;
  color: #999;
  text-align: right;
}
.tabel-text2{
  // text-indent: 10px;
}
.school-texts{
  display: inline-block !important;
}
</style>