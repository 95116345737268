<template>
  <NModal
    v-model:show="modalShow"
    preset="dialog"
    :title="distributeTitle"
    @TrainingBook="TrainingBook"
    style="width: 900px;"
    :show-icon="false"
    :mask-closable="false"
    @after-leave="resetModal"
  >
    <template #default>
      <NSteps :current="currentStep" :status="currentStatus" style="margin: 30px 0 30px;">
        <NStep v-if="!fixContent" title="选择学校" /> 
        <!-- <NStep title="选择学校" /> -->
        <NStep title="核对分发信息" />
      </NSteps>
      <Step1
        v-if="!fixContent"
        v-show="currentStep === maxStep - 2"
        ref="step1Ref"
        @id-label-update="idLabel = $event"
        @id-jlabel-update="JidLabel = $event"
        @id-klabel-update="KidLabel = $event"
        @id-slabel-update="SidLabel = $event"
        @step-next="nextStep"
        @modal-close="closeModal"
        v-bind="$attrs"
      />
      <Step3
        v-show="currentStep === maxStep - 1"
        @step-pre="preStep"
        @modal-close="closeModal"
        @distribute-success="emit('distribute-success')"
        :id-label="idLabel"
        :id-jlabel="JidLabel"
        :id-klabel="KidLabel"
        :id-slabel="SidLabel"
        :id-school="idSchool"
        :TrainingBook="TrainingBook"
        :courseTypeName="courseTypeName"
        v-bind="$attrs"
      />
      <div style="margin-bottom: -16px;"></div>
    </template>
    <template #action>
      <div></div>
    </template>
  </NModal>
</template>

<script setup>
  import { ref, defineExpose, computed, nextTick } from 'vue';

  import Step1 from './components/Step1.vue';
  import Step2 from './components/Step2.vue';
  import Step3 from './components/Step3.vue';

  const emit = defineEmits(['distribute-success']);
  const props = defineProps({
    distributeTitle: {
      type: String,
      default: ''
    },
    courseTypeName: {
      type: String,
      default: ''
    },
    TrainingBook:Array,
    fixContent: {
      type: Boolean,
      default: false
    }
  });

  const maxStep = computed(() => {
    return props.fixContent ? 2 : 3;
  });

  const step1Ref = ref(null);
  const step2Ref = ref(null);
  
  const modalShow = ref(false);
  const openModal = () => {
    modalShow.value = true;
    const TrainingBook = props.TrainingBook
    // console.log('TrainingBook',props.TrainingBook);
    if (props.fixContent) {
      nextTick(() => {
        step2Ref.value.initTableData();
      });
    } else {
      step1Ref.value && step1Ref.value.handleClear();
    }
  };
  const resetModal = () => {
    currentStep.value = 1;
    step1Ref.value.resetStep();
    // step2Ref.value.resetStep();
  };
  const closeModal = () => {
    modalShow.value = false;
  }; 

  const currentStep = ref(1);
  const currentStatus = ref('process');
  const nextStep = () => {
    const tempMaxStep = maxStep.value;
    if (currentStep.value < tempMaxStep) {
      currentStep.value += 1;
    }
    // if (tempMaxStep > 2 && currentStep.value === 2) {
    //   step2Ref.value.initTableData();
    // }
  };
  const preStep = () => {
    if (currentStep.value > 1) {
      currentStep.value -= 1;
    }
  };

  const idLabel = ref({});
  const JidLabel = ref({});
  const KidLabel = ref({});
  const SidLabel = ref({});
  
  const idSchool = ref({});

  defineExpose({
    openModal
  });
</script>