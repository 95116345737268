import { getFileTypeFromExt } from '@/enumerators/file-type.js';
import { getExtWithDotFromFileName } from '@/utils/utils.js';

export function generateEditAddLocalFileItem({ id, file_name, file_size, extension, video_id, file_url, play_url }) {
  const size = Number(file_size) || 0;
  const ext = extension || getExtWithDotFromFileName(file_name || file_url) || '';
  return {
    id,
    savedId: id,
    name: file_name || '',
    size,
    ext,
    fileType: getFileTypeFromExt(ext),
    videoId: video_id || '',
    status: 'pending',
    file: {
      size
    },
    saved: true,
    fileUrl: file_url,
    playSource: play_url
  };
}